import * as React from "react";
import { LocalizedComponentProps } from "@shared-ui/localization-context";
import { Controller, logger } from "bernie-core";
import { Logger } from "bernie-logger";
import { AnalyticsStore, PageStore } from "bernie-plugin-mobx";
import { AdvertisingStore } from "bernie-plugin-ads-loader-script";
import { FlexFooter } from "components/footer/FlexFooter";
import { TracingInfo } from "components/footer/TracingInfo";
import RedirectBanner from "components/redirectBanner/RedirectBannerWrapper";
import NavRedirectBannerWrapper from "components/redirectBanner/NavRedirectBannerWrapper";
import { VirtualAgentControl } from "@shared-ui/customer-virtual-agent-control";
import { AdScripts } from "components/utility/Ads/AdScripts";
import { doGlassbox } from "components/utility/analytics/FlexAnalyticsUtils";
import { getQueryParamValue, hasQueryParamValue } from "src/components/utility/UrlUtils";
import { Notification } from "components/utility/Notification/Notification";
import { SchemaOrgScript } from "components/utility/SchemaOrg/SchemaOrgScript";
import { inject } from "mobx-react";
import { QualtricsScript } from "components/utility/Qualtrics/QualtricsScript";
import { CompositionStore } from "src/stores/CompositionStore";
import { FlexViewModelStore } from "stores/flexViewModel/FlexViewModelStore";
import { ExperimentContext, ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import {
  BackgroundColorTokens,
  PageBackgroundContext,
} from "src/components/flexComponents/PageLevelProviders/PageBackgroundColor/PageBackgroundContext";
import { ClientChatbotStore } from "stores/chatbotStore/ClientChatbotStore";
import { RefProviderRoot } from "@shared-ui/ref-context";
import { StackedStickyProvider } from "components/shared/StackedSticky/StackedSticky";
import { ProvidedStateWrapper } from "src/components/shared/ProvidedStateWrapper/ProvidedStateWrapper";
import { Header } from "@gc/global-components";
import { GlobalNavigationLineOfBusiness } from "@shared-ui/global-navigation-header";
import { BlossomComponents } from "components/flexFramework/BlossomComponents/BlossomComponents";
import { blossomCodeSplitter } from "components/flexFramework/CodeSplitter/BlossomCodeSplitter";
import { DefaultExperienceTemplateComponentWrapper } from "components/flexFramework/ExperienceTemplateComponentWrapper";
import {
  ExperienceTemplateRenderer,
  ExperienceTemplateRendererContextProvider,
} from "experience-template-renderer-react";
import { ExperienceTemplateStore } from "stores/ExperienceTemplateStore";
import { DatadogRUMFlexExperiments } from "src/components/utility/DatadogRUMFlexExperiments";
import { SignalEventsContextProvider } from "@shared-ui/signal-events-context";
import { PubSubEventManager } from "@shared-ui/signal-events-context_pub-sub-observer";
import { LayoutContextProvider } from "@shared-ui/layout-context";
import { ErrorBoundary } from "components/errorBoundaries/errorBoundaries";
import { ApolloServerLinkInjector } from "src/components/utility/ApolloInjector";
import { PropertyDetailsAdaptExProvider } from "@shared-ui/lodging-property-details";
import { ProductGalleryAdaptExProvider } from "@shared-ui/retail-product-gallery";
import { EventWrapper } from "./EventWrapper";
import { DelayFabEntryExperiment } from "src/components/utility/DelayFabEntryExperiment";

export interface LandingPageProps extends LocalizedComponentProps {
  advertising?: AdvertisingStore;
  analytics?: AnalyticsStore;
  flexViewModel?: FlexViewModelStore;
  context?: ExtendedContextStore;
  compositionStore?: CompositionStore;
  page?: PageStore;
  chatbot?: ClientChatbotStore;
  route?: Controller;
  experienceTemplateStore?: ExperienceTemplateStore;
}

interface LandingPageStates {
  redirectBanner: React.ReactElement | null;
}

@logger("LandingPage")
@inject(
  "advertising",
  "analytics",
  "flexViewModel",
  "context",
  "compositionStore",
  "page",
  "chatbot",
  "flexModuleModelStore",
  "experienceTemplateStore"
)
export default class FlexPage extends React.Component<LandingPageProps & { logger?: Logger }, LandingPageStates> {
  constructor(props: LandingPageProps & { logger?: Logger }) {
    super(props);
    this.shouldDisplayChatbot = this.shouldDisplayChatbot.bind(this);
  }

  public componentDidMount() {
    const { context, analytics, chatbot } = this.props;

    // Record user's interactions with the page if enabled
    if (this.shouldPerformGlassboxAnalytics(context?.experimentContext)) {
      doGlassbox();
    }

    if (analytics) {
      analytics.markPageUsable();
    }

    if (this.shouldDisplayChatbot()) {
      chatbot!.injectChatbotScriptAndInitializeOnLoad(document, analytics!, this.props.logger!);
    }
  }

  private shouldPerformGlassboxAnalytics(context?: ExperimentContext): boolean {
    if (!context) {
      return false;
    }

    const forced = getQueryParamValue("forceGlass") === "true";
    const isStorefrontExperimentEnabled = context.experiments["Flex_Blossom_Glassbox_Throttle"] === 1;
    const isHTGGlassboxEnabled = context.experiments["Flex_HTG_Blossom_Glass_Throttle"] === 1;

    return isStorefrontExperimentEnabled || isHTGGlassboxEnabled || forced;
  }

  // Prevent the page from being rendered twice
  public shouldComponentUpdate(nextProps: any, nextState: any) {
    return false;
  }

  private shouldDisplayChatbot(): boolean {
    const { chatbot, analytics } = this.props;

    return !!(
      chatbot?.chatbotUseVacPath &&
      analytics &&
      chatbot?.locale &&
      chatbot?.injectChatbotScriptAndInitializeOnLoad
    );
  }

  private addSeoSchema = (): { itemScope: boolean; itemType: string } | null => {
    const { context } = this.props;
    const pageType = context?.searchContext?.pageType || "";
    const brand = context?.site?.brand;
    if (pageType === "Destination-Discovery") {
      return {
        itemScope: true,
        itemType: "http://schema.org/LocalBusiness",
      };
    }

    if (pageType === "Hotel-Information" || pageType === "Hotel-Reviews") {
      if (brand === "vrbo") {
        return {
          itemScope: true,
          itemType: "http://schema.org/Product http://schema.org/Accommodation",
        };
      }

      return {
        itemScope: true,
        itemType: "http://schema.org/Hotel",
      };
    }

    return null;
  };

  private getBackgroundColor(context: ExtendedContextStore): BackgroundColorTokens {
    if (context.site.brand === "hotwire") {
      return BackgroundColorTokens.BACKGROUND_NEUTRAL_1_50;
    }

    return BackgroundColorTokens.BACKGROUND_WHITE;
  }

  public render() {
    const {
      context,
      flexViewModel,
      compositionStore,
      chatbot,
      analytics,
      advertising,
      experienceTemplateStore,
    } = this.props;

    // This shouldn't happen but just in case
    if (!context || !flexViewModel || !compositionStore || !experienceTemplateStore) {
      return null;
    }

    // Core logic
    // Either composition, template or experienceTemplate is populated
    const flexComponents = compositionStore.composition?.page.regionList;
    const experienceTemplateComponents = experienceTemplateStore.components;

    // Set a default (empty) value for the Flex experiment context when on the experience-template path
    if (!context.experimentContext) {
      context.experimentContext = { experiments: {} };
    }
    // Non-essentials
    const trackingContext = context.experienceContext
      ? context.experienceContext.trackingContext
      : flexViewModel.trackingContext;
    advertising?.trigger(trackingContext.pageName);
    if (context.queryParams) {
      advertising?.overrideWithQueryParams(context?.queryParams);
    }
    const isCanary = context.queryParams?.["egcanary-us-east-1"] === "true";
    const shouldShowChatbot = !!(chatbot?.chatbotUseVacPath && chatbot?.locale && analytics);
    const isMobileWebView = hasQueryParamValue("webview", "1", context);
    const backgroundColorToken = this.getBackgroundColor(context);

    return (
      <ProvidedStateWrapper>
        <SignalEventsContextProvider eventManager={PubSubEventManager}>
          <StackedStickyProvider>
            <PropertyDetailsAdaptExProvider>
              <ProductGalleryAdaptExProvider>
                <RefProviderRoot>
                  <PageBackgroundContext.Provider value={backgroundColorToken}>
                    <ApolloServerLinkInjector />
                    <DatadogRUMFlexExperiments context={context} />
                    <NavRedirectBannerWrapper />
                    <RedirectBanner context={context} />
                    <div className={backgroundColorToken}>
                      {!isMobileWebView && (
                        <ErrorBoundary key="global-header" id="global-header">
                          <Header lineOfBusiness={GlobalNavigationLineOfBusiness["UNKNOWN"]} />
                        </ErrorBoundary>
                      )}
                      <div className={`${context.searchContext?.pageType || ""}`} {...this.addSeoSchema()}>
                        <EventWrapper>
                          <div role="main">
                            <LayoutContextProvider useViewportMediaQueries>
                              {flexComponents ? (
                                <BlossomComponents flexComponents={flexComponents} context={context} />
                              ) : (
                                <ExperienceTemplateRendererContextProvider
                                  libraryKeyResolver={blossomCodeSplitter.experienceTemplateLibraryKeyResolver}
                                  library={blossomCodeSplitter.componentLibrary}
                                  wrapper={DefaultExperienceTemplateComponentWrapper}
                                >
                                  {experienceTemplateComponents ? (
                                    <ExperienceTemplateRenderer templateComponents={experienceTemplateComponents} />
                                  ) : null}
                                </ExperienceTemplateRendererContextProvider>
                              )}
                            </LayoutContextProvider>
                          </div>
                        </EventWrapper>

                        {shouldShowChatbot && (
                          <DelayFabEntryExperiment authState={context.user.authState} pageId={context.pageId}>
                            {/*The span here is only for testing purposes since
                        we cannot test the presence of the shared ui component*/}
                            <span data-testid="landing-page-chatbot" />
                            <VirtualAgentControl
                              data-testid="landing-page-chatbot"
                              inputs={{}}
                              applicationName="blossom-flex-ui"
                              pageName={trackingContext.pageName}
                              clientOverrides={{
                                subscribedEvents: chatbot!.subscribedEvents,
                                eventHandler: (event) => chatbot!.eventHandler(analytics!, event),
                              }}
                              skipSsr={true}
                            />
                          </DelayFabEntryExperiment>
                        )}
                        {!isMobileWebView && <FlexFooter context={context} />}
                      </div>
                      <Notification />
                    </div>
                    <TracingInfo extendedContext={context} />
                    <AdScripts />
                    <SchemaOrgScript structuredData={flexViewModel.structuredData} />
                    {!isCanary && <QualtricsScript context={context} pageName={trackingContext.pageName} />}
                  </PageBackgroundContext.Provider>
                </RefProviderRoot>
              </ProductGalleryAdaptExProvider>
            </PropertyDetailsAdaptExProvider>
          </StackedStickyProvider>
        </SignalEventsContextProvider>
      </ProvidedStateWrapper>
    );
  }
}

export { FlexPage };
