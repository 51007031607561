import * as React from "react";
import { RedirectBanner } from "@shared-ui/retail-global-navigation-redirect-banner";
import { Experiment, ExperimentControl, ExperimentVariant } from "@shared-ui/experiment-context";

export const NavRedirectBannerWrapper = () => (
  <Experiment name="Nav_Redirect_Banner">
    <ExperimentControl />
    <ExperimentVariant bucket={1}>
      <div>
        <RedirectBanner inputs={{}} />
      </div>
    </ExperimentVariant>
  </Experiment>
);

export default NavRedirectBannerWrapper;
