import { datadogRum } from "@datadog/browser-rum-slim";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

interface DatadogRUMFlexExperimentsProps {
  context: ExtendedContextStore;
}

export const DatadogRUMFlexExperiments: React.FC<DatadogRUMFlexExperimentsProps> = ({ context }) => {
  //datadogRum is initilized in bernie plugin
  Object.entries(context.experimentContext.experiments).forEach(([name, bucket]) => {
    datadogRum.setGlobalContextProperty(`tnl.${name}`, bucket);
  });

  datadogRum.setGlobalContextProperty("tnl.authentication_state", context.user.authState);

  return null;
};
